import { useModalStateStore } from '../../state/modals';

export type MenuLink = { label: string } & (
  | { link: string }
  | { action: () => void }
  | { submenu: MenuLink[] }
);

export const useMenuConfig = () => {
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));

  const buttons: MenuLink[] = [
    {
      label: 'reviews',
      link: '/reviews',
    },
    {
      label: 'resources',
      submenu: [
        {
          label: 'tenantResources',
          link: '/resources',
        },
        {
          label: 'tenantUnions',
          link: '/tenant-unions',
        },
      ],
    },
    {
      label: 'about',
      submenu: [
        {
          label: 'faq',
          link: '/faq',
        },
        {
          label: 'contact',
          action: openContactUsModal,
        },
      ],
    },
  ];

  return { buttons };
};
