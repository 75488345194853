// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
  margin: 0 auto;
}

a,
a:active {
  color: #d90368;
  text-decoration: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;;EAEE,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["body {\n  padding: 0;\n  margin: 0 auto;\n}\n\na,\na:active {\n  color: #d90368;\n  text-decoration: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
