import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ENV } from '../../constants/common';
import { languageOptions } from '../../i18n/translations';
import NestedHoverMenuItem from './NestedHoverMenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMenuConfig } from './config';
import { useModalStateStore } from '../../state/modals';
import { useState } from 'react';

const FullMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buttons } = useMenuConfig();
  const { t, i18n } = useTranslation();
  const { openSubmitReviewModal } = useModalStateStore(({ openSubmitReviewModal }) => ({
    openSubmitReviewModal,
  }));

  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<null | HTMLElement>(null);
  const languageMenuOpen = Boolean(languageMenuAnchorEl);

  const handleLanguageIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = (languageCode?: string) => {
    if (languageCode) i18n.changeLanguage(languageCode);
    setLanguageMenuAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: '20px', gap: '15px' }}>
      {buttons.map((menulink) => {
        if ('link' in menulink || 'action' in menulink) {
          let action: () => void;
          let variant: 'contained' | 'text' = 'text';

          const { label } = menulink;

          if ('link' in menulink) {
            action = () => navigate(menulink.link);
            variant = pathname?.startsWith(menulink.link) ? 'contained' : 'text';
          } else {
            action = menulink.action;
          }
          return (
            <Button
              onClick={action}
              key={label}
              sx={{
                my: 2,
                display: 'block',
                textTransform: 'none',
              }}
              variant={variant}
            >
              {t(`header.menu.buttons.${label}.label`)}
            </Button>
          );
        } else {
          return <NestedHoverMenuItem key={menulink.label} {...menulink} />;
        }
      })}
      <Box ml="auto" display="flex">
        <Button
          onClick={openSubmitReviewModal}
          variant="contained"
          sx={{
            display: 'block',
            marginY: '10px',
            textTransform: 'none',
          }}
        >
          {t('header.menu.buttons.submitReview.label')}
        </Button>

        <Button
          variant="text"
          name="Language Select Button"
          onClick={handleLanguageIconClick}
          sx={{
            fontSize: '1.5rem',
            padding: '2px',
            ':hover': { backgroundColor: 'rgba(0, 0, 0, 0)' },
          }}
        >
          <FontAwesomeIcon icon={faLanguage} />
        </Button>
        <Menu
          id="language-menu"
          anchorEl={languageMenuAnchorEl}
          open={languageMenuOpen}
          onClose={() => handleLanguageMenuClose()}
          MenuListProps={{
            'aria-labelledby': 'language-button',
          }}
        >
          {Object.entries(languageOptions)
            .filter(([_, { live }]) => live || ENV !== 'prod')
            .map(([languageCode, { nativeName }]) => (
              <MenuItem
                key={`lngmenu-${nativeName}`}
                onClick={() => handleLanguageMenuClose(languageCode)}
              >
                {nativeName}
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default FullMenu;
