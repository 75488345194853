import { Modal } from '@mui/material';
import { FunctionComponent } from 'react';
import { useModalStateStore } from '../../state/modals';
import SubmitReviewModalBody from './SubmitReviewModalBody';

const SubmitReviewModal: FunctionComponent<Record<string, never>> = ({}) => {
  const { submitReviewOpen, closeSubmitReviewModal } = useModalStateStore(
    ({ submitReviewOpen, closeSubmitReviewModal }) => ({ submitReviewOpen, closeSubmitReviewModal })
  );

  return (
    <>
      <Modal keepMounted={false} open={submitReviewOpen} onClose={closeSubmitReviewModal}>
        <SubmitReviewModalBody />
      </Modal>
    </>
  );
};

export default SubmitReviewModal;
