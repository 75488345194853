import { ReportCallback, onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals';
import ReactGA from 'react-ga4';

const sendToGa4 = ({
  name,
  delta,
  id,
  rating,
}: {
  name: string;
  delta: number;
  id: string;
  rating: string;
  value: number;
}): void => {
  ReactGA.event('web-vitals', {
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    label: id,
    rating,
  });
};

const reportWebVitals = () => {
  const reporters: ReportCallback[] = [sendToGa4];

  for (const onPerfEntry of reporters) {
    if (onPerfEntry && onPerfEntry instanceof Function) {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    }
  }
};

export default reportWebVitals;
