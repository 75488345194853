import { MenuItem, Typography, Collapse } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpandMore from '../ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuLink } from './config';

const CollapsableMenuItem: FunctionComponent<{
  label: string;
  submenu: MenuLink[];
  expanded: boolean;
  collapseOnClick: () => void;
  closeMenu: () => void;
}> = ({ label, submenu, expanded, collapseOnClick, closeMenu }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <MenuItem key={label} onClick={collapseOnClick}>
        <Typography textTransform="none" textAlign="center">
          {t(`header.menu.buttons.${label}.label`)}
        </Typography>
        <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </MenuItem>
      <Collapse in={expanded}>
        {submenu.map((submenuItem) => {
          if ('link' in submenuItem || 'action' in submenuItem) {
            let action: () => void;
            let selected: boolean = false;

            const { label } = submenuItem;

            if ('link' in submenuItem) {
              action = () => navigate(submenuItem.link);
              selected = pathname?.startsWith(submenuItem.link);
            } else {
              action = submenuItem.action;
            }

            return (
              <MenuItem
                selected={selected}
                key={label}
                onClick={() => {
                  closeMenu();
                  action();
                }}
              >
                <Typography paddingLeft={'5px'} textTransform="none" textAlign="center">
                  {t(`header.menu.buttons.${label}.label`)}
                </Typography>
              </MenuItem>
            );
          } else {
            return <></>;
          }
        })}
      </Collapse>
    </>
  );
};

export default CollapsableMenuItem;
