import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Typography } from '@mui/material';
import { ErrorBoundary } from '../ErrorBoundary';
import ReCAPTCHA from 'react-google-recaptcha';

const FormRecaptcha = <R extends FieldValues>({
  name,
  control,
}: {
  name: Path<R>;
  control: Control<R>;
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange }, fieldState: { error } }) => (
      <ErrorBoundary fallback={<Typography>Error Loading ReCaptcha</Typography>}>
        <ReCAPTCHA
          style={{ width: 'fit-content', margin: 'auto' }}
          onChange={onChange}
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || 'MISSING_RECAPTCHA_KEY'}
        />
        <Typography
          textAlign={'center'}
          color={'#d32f2f'}
          variant="subtitle1"
          display={!!error ? 'block' : 'none'}
          component="legend"
        >
          {error?.message}
        </Typography>
      </ErrorBoundary>
    )}
  />
);

export default FormRecaptcha;
