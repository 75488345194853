import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalState {
  contactUsOpen: boolean;
  submitReviewOpen: boolean;

  openContactUsModal: () => void;
  closeContactUsModal: () => void;
  openSubmitReviewModal: () => void;
  closeSubmitReviewModal: () => void;
}

export const useModalStateStore = create<ModalState>()(
  devtools((set) => ({
    contactUsOpen: false,
    submitReviewOpen: false,

    openContactUsModal: () => set({ contactUsOpen: true }),
    closeContactUsModal: () => set({ contactUsOpen: false }),
    openSubmitReviewModal: () => set({ submitReviewOpen: true }),
    closeSubmitReviewModal: () => set({ submitReviewOpen: false }),
  }))
);
