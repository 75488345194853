import Message from '@mui/icons-material/Message';
import Instagram from '@mui/icons-material/Instagram';
import { Box, Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useModalStateStore } from '../state/modals';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ENV } from '../constants/common';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));
  const navigate = useNavigate();

  return (
    <>
      <Box
        component="footer"
        sx={{
          p: 0,
          pb: 0,
        }}
      >
        <Grid width="100%" container direction="row">
          <Grid item xs={6} sm={5} textAlign={'left'}>
            <Typography
              onClick={() => navigate('/privacy')}
              color="inherit"
              sx={{ color: '#D90368', textDecoration: 'none', cursor: 'pointer' }}
            >
              {t('footer.privacyPolicy')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2} textAlign={{ xs: 'right', sm: 'center' }}>
            <Message
              onClick={() => {
                openContactUsModal();
              }}
              sx={{ cursor: 'pointer', color: '#D90368' }}
            />
            {ENV !== 'prod' ? (
              <a
                aria-label="Instagram"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/landlordspotlight"
                color="black"
              >
                <Instagram sx={{ color: '#D90368' }} />
              </a>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={5} textAlign={{ xs: 'center', sm: 'right' }}>
            <Typography variant="body2" color="text.secondary">
              {'Copyright © '}
              <a color="inherit" style={{ color: '#D90368', textDecoration: 'none' }} href="/">
                Landlord Spotlight
              </a>{' '}
              {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
