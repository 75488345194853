import axios from 'axios';
import { GetLandlordsResponse, GetReviewsResponse, PresignUrlDataResponse } from './types';
import { InferType } from 'yup';
import { reviewFormSchema } from '../../components/modals/SubmitReviewModalBody';

const landlordSpotlightInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
});

/**
 * @function getReviews
 *
 * @param searchParams
 * @param { number } page
 * @param { number } limit
 * @returns { Prmoise<GetReviewsResponse> }
 */
export async function getReviews(
  {
    landlordName,
    country,
    state,
    city,
    postal,
  }: { landlordName?: string; country?: string; state?: string; city?: string; postal?: string },

  page = 1,
  limit = 25,
  controller?: AbortController
): Promise<GetReviewsResponse> {
  return landlordSpotlightInstance
    .request<GetReviewsResponse>({
      url: 'reviews',
      method: 'get',
      params: {
        landlord: landlordName,

        country,
        state,
        city,
        postal,

        page,
        limit,
      },
      signal: controller?.signal,
    })
    .then(({ data }) => data);
}

/**
 * @function submitContactFormMessage
 *
 * @param { InferType<typeof reviewFormSchema> } data
 * @returns { Prmoise<void> }
 */
export async function submitNewReview({
  recaptchaResponse,
  images,
  ...rest
}: InferType<typeof reviewFormSchema>): Promise<void> {
  const { imageUploadParams: preSignUrlData } = await landlordSpotlightInstance
    .request<{ imageUploadParams?: PresignUrlDataResponse }>({
      url: 'reviews',
      method: 'POST',
      headers: {
        recaptcha: recaptchaResponse,
      },
      data: { ...rest, includeImages: images?.length !== undefined && images.length > 0 },
    })
    .then(({ data }) => data);

  if (images && preSignUrlData) {
    await Promise.all<void>(
      images.map(async (image: File) => {
        const form = new FormData();

        for (const [key, val] of Object.entries(preSignUrlData.fields)) {
          form.append(key, val);
        }

        form.append('Content-Type', image.type);
        form.append('file', image);

        await axios.request<void>({
          baseURL: preSignUrlData.url,
          method: 'post',
          data: form,
        });
      })
    );
  }
}

/**
 * @function submitContactFormMessage
 *
 * @param { string } recaptchaResponse
 * @param { string } message
 * @param { string | undefined } replyTo
 * @returns { Prmoise<void> }
 */
export async function submitContactFormMessage(
  recaptchaResponse: string,
  message: string,
  topic?: string,
  replyTo?: string
): Promise<void> {
  await landlordSpotlightInstance.request({
    url: 'contact',
    method: 'POST',
    headers: {
      recaptcha: recaptchaResponse,
    },
    data: {
      message,
      topic,
      replyTo,
    },
  });
}

/**
 * @function fetchLandlordOptions
 *
 * @param { string } recaptchaResponse
 * @param { string } message
 * @param { string | undefined } replyTo
 * @returns { Prmoise<void> }
 */
export async function fetchLandlordOptions(
  landlordName: string,
  country: string,
  state: string,
  city: string = '',
  postal: string = ''
): Promise<GetLandlordsResponse> {
  return landlordSpotlightInstance
    .request<GetLandlordsResponse>({
      url: 'landlords',
      method: 'GET',
      params: {
        landlord: landlordName,
        country,
        state,
        city,
        postal,
      },
    })
    .then(({ data }) => data);
}
