import { Button, Menu, popoverClasses } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FunctionComponent, useState, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MenuLink } from './config';

const NestedHoverMenuItem: FunctionComponent<{ label: string; submenu: MenuLink[] }> = ({
  label,
  submenu,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let isHovering = false;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  function handleHover() {
    isHovering = true;
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCloseHover() {
    isHovering = false;
    setTimeout(() => {
      if (!isHovering) {
        handleClose();
      }
    }, 50);
  }

  return (
    <>
      <Button
        sx={{
          my: 2,
          display: 'block',
          textTransform: 'none',
        }}
        variant="text"
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={handleCloseHover}
      >
        {t(`header.menu.buttons.${label}.label`)}
        <KeyboardArrowDownIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
      </Button>
      <Menu
        id={`submenu-${label}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        hideBackdrop
        onMouseEnter={handleHover}
        onClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleHover,
          onMouseLeave: handleCloseHover,
          style: { pointerEvents: 'auto' },
          disablePadding: true,
        }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{
          [`&.${popoverClasses.root}`]: {
            pointerEvents: 'none',
          },
        }}
      >
        {submenu.map((menuitem) => {
          if ('link' in menuitem || 'action' in menuitem) {
            let action: () => void;
            const { label: menuitemLabel } = menuitem;

            if ('link' in menuitem) {
              action = () => navigate(menuitem.link);
            } else {
              action = menuitem.action;
            }

            return (
              <Button
                key={`nestedHoverMenu-${menuitemLabel}`}
                sx={{
                  my: 2,
                  textTransform: 'none',
                  py: 0,
                }}
                onClick={() => {
                  handleClose();
                  action();
                }}
              >
                {t(`header.menu.buttons.${menuitemLabel}.label`)}
              </Button>
            );
          }
        })}
      </Menu>
    </>
  );
};

export default NestedHoverMenuItem;
