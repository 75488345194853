import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getReviews } from '../providers/landlordspotlight/client';
import { Review } from '../providers/landlordspotlight/types';

export function useSearchReviews(
  searchParams: {
    country?: string | undefined;
    state?: string | undefined;
    city?: string | undefined;
    postal?: string | undefined;
  },
  pageNum: number,
  precache: boolean = false
) {
  const [fromLocalCache, setFromLocalCache] = useState(
    localStorage.getItem('reviewsCache') !== undefined
  );
  const didMountRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reviews, setReviews] = useState<Review[]>(
    JSON.parse(localStorage.getItem('reviewsCache') || '[]') || []
  );
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    didMountRef.current = true;
  }, [searchParams]);

  useEffect(() => {
    localStorage.setItem('reviewsCache', JSON.stringify(reviews.slice(0, 25)));
  }, [reviews]);

  useEffect(() => {
    if (precache && reviews.length > 0) return;

    const controller = new AbortController();
    setIsLoading(true);
    setError(false);

    getReviews(searchParams, pageNum, undefined, !precache ? controller : undefined)
      .then((res) => {
        setReviews((prev) => {
          if (didMountRef.current) return res.results;
          return [...(fromLocalCache ? [] : prev), ...res.results];
        });
        setFromLocalCache(false);
        setHasMore(
          !!res.pagination?.page && Math.ceil(res.pagination.total / res.pagination.limit) > pageNum
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setReviews([]);
        setError(err);
      });

    return () => controller.abort();
  }, [searchParams, pageNum]);

  return { isLoading, error, reviews, hasMore };
}

export default useSearchReviews;
