import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { getCountryFromTimezone } from '../utils/userLocation';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';

export type SearchParams = {
  landlordName: string;
  country: string;
  state: string;
  city: string;
  postal: string;
};

interface SearchState {
  searchParams: SearchParams;
  setSearchParams: (newParams: Partial<SearchParams>) => SearchParams;
}

function generateState(partialParams?: Partial<SearchParams>): SearchParams {
  return {
    ...{
      landlordName: '',
      country: '',
      state: '',
      city: '',
      postal: '',
    },
    ...partialParams,
  };
}

export const useSearchParamsStore = create<SearchState>()(
  devtools(
    persist(
      (set) => {
        return {
          searchParams: generateState(),
          setSearchParams: (newParams) => {
            const newParamsState = generateState(newParams);
            set({ searchParams: newParamsState });
            return newParamsState;
          },
        };
      },
      {
        name: 'savedSearchParams',
        partialize: (state) => ({
          searchParams: generateState({
            country: state.searchParams.country,
            state: state.searchParams.state,
          }),
        }),
        onRehydrateStorage: () => {
          return (state, error) => {
            if (error) {
              Sentry.captureException(error);
            } else if (!state?.searchParams.country) {
              state?.setSearchParams({
                country: getCountryFromTimezone(),
              });
            }
          };
        },
      }
    )
  )
);
