import { FunctionComponent, useEffect } from 'react';
import { Container, Box, Typography, Button, Grid, Alert } from '@mui/material';
import bannerImageJpg from './assets/images/banner.jpg';
import bannerImageWebp from './assets/images/banner.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshakeAngle,
  faMagnifyingGlass,
  faStarHalfStroke,
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModalStateStore } from './state/modals';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { removeUtms } from './utils/common';
import useSearchReviews from './hooks/useSearchReviews';
import { getCountryFromTimezone } from './utils/userLocation';

const Index: FunctionComponent = () => {
  useSearchReviews({ country: getCountryFromTimezone() }, 1, true);

  const { t } = useTranslation();
  const { openSubmitReviewModal } = useModalStateStore(({ openSubmitReviewModal }) => ({
    openSubmitReviewModal,
  }));
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: 'Landing Page',
      event_callback: removeUtms,
    });
  }, []);

  return (
    <>
      <Alert severity="warning">
        As we rapidly add new features to the site, please be aware that some sections may not have
        translations yet. We appreciate your patience
      </Alert>
      <Container
        role="banner"
        sx={{
          position: 'relative',
          width: { xs: '100%' },
          maxWidth: { xs: '100%' },
          height: '450px',
          margin: 0,
          padding: { xs: 0 },
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            zIndex: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgb(2 60 130 / 45%)',
            display: 'flex',
            backgroundImage: `url(${bannerImageJpg})`,
            [`@supports (background-image: url('${bannerImageWebp}'))`]: {
              backgroundImage: `url(${bannerImageWebp})`,
            },
            backgroundClip: 'content-box',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            sx={{
              backgroundColor: 'rgb(2 60 130 / 45%)',
            }}
          >
            <Box sx={{ margin: 'auto', textAlign: 'center', maxWidth: '850px' }}>
              <Typography
                sx={{
                  fontSize: { xs: '1.5rem', sm: '2.2rem' },
                  color: 'white',
                  textShadow: '3px 2px 8px black',
                }}
              >
                {t('landingPage.banner.headline')}
              </Typography>
              <Container
                sx={{
                  display: { xs: 'grid', sm: 'block' },
                  marginTop: { xs: '10px' },
                  gap: { xs: '10px 0' },
                }}
              >
                <Button
                  sx={{
                    marginX: '5px',
                    backgroundColor: 'white',
                    ':hover': { backgroundColor: 'rgb(210 233 255)' },
                  }}
                  variant="outlined"
                  onClick={() => navigate('/reviews')}
                >
                  {t('landingPage.banner.buttons.viewReviews.label')}
                </Button>
                <Button onClick={openSubmitReviewModal} sx={{ marginX: '5px' }} variant="contained">
                  {t('landingPage.banner.buttons.submitReview.label')}
                </Button>
              </Container>
            </Box>
          </Box>
        </Box>
      </Container>
      <Container
        sx={{
          position: 'relative',
          width: { xs: '100%' },
          maxWidth: { xs: '100%' },
          minHeight: '100px',
          height: 'fit-content',
          margin: 0,
          padding: { xs: 0 },
          overflow: 'hidden',
          marginBottom: { xs: '10px', sm: '80px' },
        }}
      >
        <Grid container spacing={0} padding={'10px'} gap={'40px 0'} textAlign="center">
          <Grid item xs={12} py={'10px'}>
            <Typography variant="h2">{t('landingPage.callsToAction.headline')}</Typography>
          </Grid>
          {[
            {
              heading: t('landingPage.callsToAction.accountability.heading'),
              subHeading: t('landingPage.callsToAction.accountability.body'),
              icon: faHandshakeAngle,
            },
            {
              heading: t('landingPage.callsToAction.transparency.heading'),
              subHeading: t('landingPage.callsToAction.transparency.body'),
              icon: faMagnifyingGlass,
            },
            {
              heading: t('landingPage.callsToAction.raiseStandards.heading'),
              subHeading: t('landingPage.callsToAction.raiseStandards.body'),
              icon: faStarHalfStroke,
            },
          ].map(({ heading, subHeading, icon }, idx) => (
            <Grid key={`callstoaction-${idx}`} item xs={12} md={4} padding={'5px'}>
              <FontAwesomeIcon fontSize="5rem" color={theme.palette.primary.main} icon={icon} />
              <Typography sx={{ marginBottom: { sm: '5px', md: '15px' } }} variant="h3">
                {heading}
              </Typography>
              <Typography>{subHeading}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Index;
