import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationResources } from './translations';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translationResources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  document.querySelector("meta[name='language']")?.setAttribute('content', lng);
});
