import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Typography, Divider, Select } from '@mui/material';
import { ENV } from '../../constants/common';
import { languageOptions } from '../../i18n/translations';
import CollapsableMenuItem from './CollapsableMenuItem';
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenuConfig } from './config';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModalStateStore } from '../../state/modals';

const MobileMenu: FunctionComponent<{
  anchorElNav: HTMLElement | null;
  setAnchorElNav: Dispatch<SetStateAction<HTMLElement | null>>;
}> = ({ anchorElNav, setAnchorElNav }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buttons } = useMenuConfig();
  const { t, i18n } = useTranslation();
  const { openSubmitReviewModal } = useModalStateStore(({ openSubmitReviewModal }) => ({
    openSubmitReviewModal,
  }));

  const [expanded, setExpanded] = useState<string | null>(null);

  const handleCloseNavMenu = () => {
    setExpanded(null);
    setAnchorElNav(null);
  };

  const handleLanguageMenuClose = (languageCode?: string) => {
    if (languageCode) i18n.changeLanguage(languageCode);
  };

  return (
    <Menu
      id="menubar"
      anchorEl={anchorElNav}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
      {buttons.map((menulink) => {
        if ('link' in menulink || 'action' in menulink) {
          let action: () => void;
          let selected: boolean = false;

          const { label } = menulink;

          if ('link' in menulink) {
            action = () => navigate(menulink.link);
            selected = pathname?.startsWith(menulink.link);
          } else {
            action = menulink.action;
          }

          return (
            <MenuItem
              selected={selected}
              key={label}
              onClick={() => {
                handleCloseNavMenu();
                action();
              }}
            >
              <Typography textTransform="none" textAlign="center">
                {t(`header.menu.buttons.${label}.label`)}
              </Typography>
            </MenuItem>
          );
        } else {
          return (
            <CollapsableMenuItem
              key={menulink.label}
              closeMenu={handleCloseNavMenu}
              expanded={expanded === menulink.label}
              collapseOnClick={() =>
                setExpanded((prev) => {
                  if (prev === menulink.label) return null;
                  else return menulink.label;
                })
              }
              {...menulink}
            />
          );
        }
      })}
      <Divider key={`submitReviewMenuItem-divider`} />
      <MenuItem
        key={'submitReviewMenuItem'}
        onClick={() => {
          openSubmitReviewModal();
          handleCloseNavMenu();
        }}
      >
        {t('header.menu.buttons.submitReview.label')}
      </MenuItem>
      <Divider />
      <MenuItem>
        <Select
          key={`langselect-${i18n.language}`}
          variant="standard"
          sx={{
            padding: '0',
            border: 'none',
            ':hover': { '::before': { borderBottom: 'none !important' } },
            '::before': { borderBottom: 'none' },
          }}
          defaultValue={i18n.language}
          value={i18n.language}
          onChange={({ target: { value } }) => handleLanguageMenuClose(value)}
          renderValue={(value: string) => (
            <>
              <FontAwesomeIcon icon={faLanguage} />{' '}
              {languageOptions[value as keyof typeof languageOptions]?.nativeName || 'Language'}
            </>
          )}
        >
          {Object.entries(languageOptions)
            .filter(([_, { live }]) => live || ENV !== 'prod')
            .map(([languageCode, { nativeName }]) => (
              <MenuItem key={`lngmenu-${nativeName}`} value={languageCode}>
                {nativeName}
              </MenuItem>
            ))}
        </Select>
      </MenuItem>
    </Menu>
  );
};

export default MobileMenu;
