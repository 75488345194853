import './global.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { ANALYTICS_ID, ENV, SENTRY_DSN, SENTRY_ENABLED, SENTRY_ENV } from './constants/common';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './utils/reportWebVitals';
import Layout from './components/Layout';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import Index from '.';
import ErrorPage from './pages/ErrorPage';
import ContactUsModal from './components/modals/ContactUsModal';
import SubmitReviewModal from './components/modals/SubmitReviewModal';
import { lazy, useEffect } from 'react';
import { useModalStateStore } from './state/modals';
import ReactGA from 'react-ga4';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { theme } from './constants/themes';
import { ToastContainer } from 'react-toastify';

try {
  ReactGA.initialize(ANALYTICS_ID, {
    gaOptions: {
      forceSSL: true,
    },
  });
} catch (e) {}

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENABLED,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
});

const Reviews = lazy(() => import('./pages/Reviews'));
const Resources = lazy(() => import('./pages/Resources'));
const Privacy = lazy(() => import('./pages/Privacy'));
const TenantUnions = lazy(() => import('./pages/TenantUnions'));
const Faq = lazy(() => import('./pages/Faq'));

const Contact = () => {
  const navigate = useNavigate();
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));

  useEffect(() => {
    openContactUsModal();
    navigate('/');
  }, []);

  return <Index />;
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Index /> },
      { path: '/contact', element: <Contact /> },
      {
        path: '/reviews/new',
        element: <Reviews newReview />,
      },
      {
        path: '/reviews',
        element: <Reviews />,
      },
      {
        path: '/resources/',
        children: [
          {
            index: true,
            element: <Resources />,
          },
          {
            path: ':countryCode',
            element: <Resources />,
          },
        ],
      },
      { path: '/tenant-unions', element: <TenantUnions /> },
      { path: '/faq', element: <Faq /> },
      {
        path: '/privacy',
        element: <Privacy />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.querySelector("meta[name='language']")?.setAttribute('content', i18n.language);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={router} />
      <SubmitReviewModal />
      <ContactUsModal />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Could not get 'root' element");
else createRoot(rootElement).render(<App />);

reportWebVitals();

if (ENV === 'dev' || ENV === 'prod') serviceWorker.register();
