import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  ToggleButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldErrors, useForm } from 'react-hook-form';
import FormCountryAutocomplete from '../hookedForm/FormCountryAutocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSearchParamsStore } from '../../state/app';
import { getCountryFromTimezone } from '../../utils/userLocation';
import FormStateAutocomplete from '../hookedForm/FormStateAutoComplete';
import FormCityAutocomplete from '../hookedForm/FormCityAutocomplete';
import FormTextField from '../hookedForm/FormTextField';
import { LANDLORD_TYPE } from '../../constants/types';
import { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useModalStateStore } from '../../state/modals';
import FormCheckbox from '../hookedForm/FormCheckbox';
import * as Sentry from '@sentry/react';
import FormRecaptcha from '../hookedForm/FormRecaptcha';
import { submitNewReview } from '../../providers/landlordspotlight/client';
import { toast } from 'react-toastify';
import FormImageUploadPreviewer from '../hookedForm/FormImageUploadPreviewer';
import FormToggleGroup from '../hookedForm/FormToggleGroup';
import FormLandlordAutocomplete from '../hookedForm/FormLandlordAutocomplete';
import FormRating from '../hookedForm/FormRating';
import { postalCodeYupTest } from '../../utils/yupValidators';

export const reviewFormSchema = yup.object().shape(
  {
    propertyCountry: yup.string().required('Required'),
    propertyState: yup.string().required('Required'),
    propertyCity: yup.string(),
    propertyPostal: yup
      .string()
      .test('ensure_valid_postal', '', postalCodeYupTest('propertyCountry', 'propertyState')),
    propertyAddress: yup.string().optional(),

    landlordType: yup.mixed<LANDLORD_TYPE>().oneOf(Object.values(LANDLORD_TYPE)).required(),
    landlordName: yup.string().required('Required'),

    reviewRepair: yup.number().required('Required').min(0.5, 'Required').max(5, 'Required'),
    reviewStability: yup.number().required('Required').min(0.5, 'Required').max(5, 'Required'),
    reviewPrivacy: yup.number().required('Required').min(0.5, 'Required').max(5, 'Required'),
    reviewRespect: yup.number().required('Required').min(0.5, 'Required').max(5, 'Required'),
    reviewHeadline: yup.string().matches(/.{20,}/, {
      excludeEmptyString: true,
      message: 'Must be at least 20 characters',
    }),
    reviewBody: yup.string().required('Required').min(100, 'Must be at least 100 characters'),

    confirmOne: yup.boolean().oneOf([true], 'Required'),
    confirmTwo: yup.boolean().oneOf([true], 'Required'),
    confirmThree: yup.boolean().oneOf([true], 'Required'),

    images: yup.array().max(3, 'A maximum of 3 images can be selected').optional(),

    recaptchaResponse: yup.string().required('Required'),
  },
  [['propertyPostal', 'propertyPostal']]
);

const SubmitReviewModalBody = forwardRef(() => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { t } = useTranslation();
  const { closeSubmitReviewModal } = useModalStateStore(({ closeSubmitReviewModal }) => ({
    closeSubmitReviewModal,
  }));
  const { searchParams } = useSearchParamsStore();
  const {
    setValue,
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<yup.InferType<typeof reviewFormSchema>>({
    defaultValues: {
      propertyCountry: searchParams.country || getCountryFromTimezone(),
      propertyState: searchParams.state || '',
      propertyCity: '',
      propertyPostal: '',
      propertyAddress: '',

      landlordType: LANDLORD_TYPE.IND,
      landlordName: '',

      reviewRepair: 0,
      reviewStability: 0,
      reviewPrivacy: 0,
      reviewRespect: 0,
      reviewHeadline: '',
      reviewBody: '',

      images: [],

      confirmOne: false,
      confirmTwo: false,
      confirmThree: true,

      recaptchaResponse: '',
    },
    resolver: yupResolver(reviewFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleSubmitReview = async (
    formValues: yup.InferType<typeof reviewFormSchema>
  ): Promise<void> => {
    toast.info('Submitting', {
      autoClose: false,
      toastId: 'newReviewSubmitting',
    });
    await submitNewReview(formValues)
      .then(() => {
        toast.update('newReviewSubmitting', {
          type: 'success',
          autoClose: 4000,
          render() {
            return (
              <>
                Thank you for your review! Once it's been reviewed, it will be available for
                everyone to see
              </>
            );
          },
        });
        setSubmitSuccess(true);
        new Promise<void>((done) => setTimeout(done, 1000));
      })
      .then(closeSubmitReviewModal)
      .catch((err: Error) => {
        toast.update('newReviewSubmitting', {
          type: 'error',
          autoClose: 5000,
          render() {
            return <>There was an error saving your review. Please try agian.</>;
          },
        });
        Sentry.captureException(err);
      });
  };

  const handleSubmitReviewError = (err: FieldErrors<yup.InferType<typeof reviewFormSchema>>) => {
    toast.error('There are still required fields missing', {
      autoClose: 2000,
      toastId: 'newReviewMissingFieldsError',
    });

    Sentry.captureEvent({
      message: 'Validation errors while submitting review',
      type: 'transaction',
      tags: Object.entries(err).reduce((acc, [key, { message }]) => {
        return { ...acc, [key]: message || '' };
      }, {}),
    });
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: {
          xs: '100%',
          sm: '85%',
        },
        height: {
          xs: '100%',
          sm: 'fit-content',
        },
        maxWidth: '850px',
        width: {
          xs: '100%',
          sm: '90%',
          md: '80%',
        },
        overflowY: 'scroll',
        p: '20px 5px',
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: { xs: '0px', sm: '8px' },
        boxShadow: 24,
      }}
    >
      <Button
        sx={{ position: 'absolute', top: '5px', right: '0' }}
        onClick={closeSubmitReviewModal}
      >
        <FontAwesomeIcon size="2x" icon={faXmark} />
      </Button>
      <Typography variant="h2">Submit a new review</Typography>
      <FormControl disabled={isSubmitting}>
        <Container component="section">
          <Alert severity="info" sx={{ my: '5px' }}>
            We highly encourage users to submit their reviews after their leases have concluded to
            reduce the risk of potential retaliation.
          </Alert>
          <Typography variant="h3">Property information</Typography>
          <Typography p={'5px'}>
            We ask for this first to help narrow down landlord field suggestions. This is the
            location of the rental property and <strong>not</strong> that of the landlord.
          </Typography>
          <Grid container spacing={'10px'}>
            <Grid item xs={12} sm={6} md={4}>
              <FormCountryAutocomplete
                name="propertyCountry"
                control={control}
                setValue={setValue}
                fieldsToClear={['propertyState', 'propertyCity', 'propertyPostal']}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormStateAutocomplete
                name="propertyState"
                control={control}
                setValue={setValue}
                countryCode={watch('propertyCountry')}
                fieldsToClear={['propertyCity', 'propertyPostal']}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormCityAutocomplete
                key={'city-autocomplete'}
                name="propertyCity"
                control={control}
                setValue={setValue}
                countryCode={watch('propertyCountry')}
                stateCode={watch('propertyState')}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                name="propertyPostal"
                control={control}
                label={t('reviewsPage.filters.postal.label')}
                disabled={!watch('propertyCountry') || !watch('propertyState')}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormTextField
                name="propertyAddress"
                control={control}
                label={t('Address (Optional)')}
                disabled={!watch('propertyCountry') || !watch('propertyState')}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography variant="h3">Landlord Information</Typography>
          <Typography p={'5px'}>
            This is the landlord information as it would appear on your lease agreement. If the
            property is managed by a property management company, use the name of the company and
            not any representative you may have been communicating with.
          </Typography>
          <Container
            disableGutters
            sx={{ display: 'flex', gap: '10px', flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <FormToggleGroup
              size="small"
              name="landlordType"
              control={control}
              exclusive
              sx={{
                width: { xs: '100%', sm: 'auto' },
                height: 'fit-content',
              }}
              color="primary"
            >
              <ToggleButton sx={{ width: { xs: '100%', sm: 'auto' } }} fullWidth value={'IND'}>
                Individual
              </ToggleButton>
              <ToggleButton sx={{ width: { xs: '100%', sm: 'auto' } }} value={'CORP'}>
                Company
              </ToggleButton>
            </FormToggleGroup>
            <FormLandlordAutocomplete
              setValue={setValue}
              name="landlordName"
              control={control}
              label="Landlord"
              country={watch('propertyCountry')}
              state={watch('propertyState')}
            />
          </Container>
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography variant="h3">Review</Typography>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormRating colored name="reviewRepair" control={control} label={t('Repair')} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormRating colored name="reviewStability" control={control} label={t('Stability')} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormRating colored name="reviewPrivacy" control={control} label={t('Privacy')} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormRating colored name="reviewRespect" control={control} label={t('Respect')} />
            </Grid>
          </Grid>

          <Grid item xs={12} mt={'15px'}>
            <FormTextField
              name="reviewHeadline"
              control={control}
              label={t('Headline (Optional)')}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} mt={'15px'}>
            <FormTextField
              name="reviewBody"
              control={control}
              label={t('Review')}
              charCount={100}
              multiline
              minRows={3}
              maxRows={6}
              autoComplete="off"
            />
          </Grid>
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography variant="h3">Images</Typography>
          <Alert severity="warning" sx={{ my: '5px' }}>
            Images may be reviewed before going live by a human or automated system. A{' '}
            <strong>third party</strong> may be used to validate images against moderation criteria.
          </Alert>
          <Typography p={'5px'}>(Optional) Add up to 3 photos to your review</Typography>

          <FormImageUploadPreviewer name="images" control={control} />
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography variant="h3">Confirmation</Typography>

          <Grid container>
            <Grid item display={'flex'} xs={1}>
              <FormCheckbox name="confirmOne" control={control} />
            </Grid>
            <Grid item xs={11} display={'flex'} padding={{ xs: '10px', sm: 'inherit' }}>
              <Typography my={'auto'}>
                You are submitting this review with the understanding that we will not remove the
                review for any reason other than it doesn't pass our internal moderation process.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mx: 'auto', my: '5px', width: '30%' }} />
            </Grid>

            <Grid item display={'flex'} xs={1}>
              <FormCheckbox name="confirmTwo" control={control} />
            </Grid>
            <Grid item xs={11} display={'flex'} padding={{ xs: '10px', sm: 'inherit' }}>
              <Typography my={'auto'}>
                You understand that we recommend submitting a review <strong>after</strong> the
                lease has concluded to limit the risk of retaliation by a landlord.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mx: 'auto', my: '5px', width: '30%' }} />
            </Grid>

            <Grid item xs={12} marginY={'15px'}>
              <FormRecaptcha name="recaptchaResponse" control={control} />
            </Grid>

            <Grid item sm={12} width={'100%'} display="flex">
              <Button
                disableRipple
                sx={{ mx: 'auto', width: { xs: '100%', sm: '150px' } }}
                variant="contained"
                onClick={handleSubmit(handleSubmitReview, handleSubmitReviewError)}
              >
                {submitSuccess ? (
                  'Success'
                ) : isSubmitting ? (
                  <CircularProgress size="24.5px" sx={{ color: '#FFF' }} />
                ) : (
                  'Submit'
                )}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </FormControl>
    </Container>
  );
});

export default SubmitReviewModalBody;
